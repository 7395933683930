"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var routes_1 = require("@utils/routes");
var rx_1 = require("@utils/rx");
var http_1 = require("@angular/common/http");
var ErrorBag_1 = require("@utils/ErrorBag");
var router_1 = require("@angular/router");
var project_reducer_1 = require("./project.reducer");
var ProjectEffects = /** @class */ (function () {
    function ProjectEffects(actions$, store, http, router) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.http = http;
        this.router = router;
        this.loadProject$ = this.actions$.pipe(rx_1.ofType('PROJECT_LOAD'), operators_1.mergeMap(function (_a) {
            var id = _a.payload;
            return _this.http.get(routes_1.PROJECTS_FIND_URL(id)).pipe(operators_1.map(function (_a) {
                var payload = _a.data;
                return new project_reducer_1.ProjectLoaded(payload);
            }), operators_1.catchError(function () { return rxjs_1.of(new project_reducer_1.ProjectNotFound()); }));
        }));
        this.projectNotFound$ = this.actions$.pipe(rx_1.ofType('PROJECT_NOT_FOUND'), operators_1.tap(function () { return _this.router.navigate(['dashboard']); }));
        this.updateProject$ = this.actions$.pipe(rx_1.ofType('PROJECT_UPDATE'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var payload = _a[0].payload, url = _a[1].url;
            return _this.http.put(url, payload);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectUpdated(payload);
        }));
        this.openTask$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_OPEN'), operators_1.mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.http.put(payload.state_url, { state: 'OPEN' });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectTaskOpened(payload);
        }));
        this.resolveTask$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_RESOLVE'), operators_1.mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.http.put(payload.state_url, { state: 'RESOLVED' });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectTaskResolved(payload);
        }));
        this.logRefreshRequested$ = this.actions$.pipe(rx_1.ofType('PROJECT_MEMBER_CREATED', 'PROJECT_MEMBER_REMOVED', 'PROJECT_TASK_OPENED', 'PROJECT_TASK_RESOLVED', 'PROJECT_ATTACHMENT_CREATED', 'PROJECT_ATTACHMENT_UPDATED', 'PROJECT_ATTACHMENT_REMOVED'), operators_1.mergeMap(function () { return _this.store.pipe(rx_1.once(function (x) { return x.project; })); }), operators_1.mergeMap(function (project) { return _this.http.get(project.logs_url); }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectLogsRefreshed(payload);
        }));
        this.createComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_CREATE'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var payload = _a[0].payload, url = _a[1].comments_url;
            return _this.http.post(url, payload);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectCommentCreated(payload);
        }));
        this.updateComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_UPDATE'), operators_1.mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.http.put(payload.url, payload);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectCommentUpdated(payload);
        }));
        this.removeComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_REMOVE'), operators_1.delayWhen(function (_a) {
            var payload = _a.payload;
            return _this.http.delete(payload.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectCommentRemoved(payload);
        }));
        this.createCommentReply$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_REPLY_CREATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, comment = _b.comment, reply = _b.reply;
            return _this.http.post(comment.comments_url, reply);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectCommentReplyCreated(payload);
        }));
        this.updateCommentReply$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_REPLY_UPDATE'), operators_1.mergeMap(function (_a) {
            var reply = _a.payload.reply;
            return _this.http.put(reply.url, reply);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectCommentReplyUpdated(payload);
        }));
        this.removeCommentReply$ = this.actions$.pipe(rx_1.ofType('PROJECT_COMMENT_REPLY_REMOVE'), operators_1.delayWhen(function (_a) {
            var reply = _a.payload.reply;
            return _this.http.delete(reply.url);
        }), operators_1.map(function (_a) {
            var reply = _a.payload.reply;
            return new project_reducer_1.ProjectCommentReplyRemoved(reply);
        }));
        this.createTaskComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_COMMENT_CREATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, task = _b.task, payload = _b.comment;
            return _this.http
                .post(task.comments_url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ task: task, comment: comment });
            }));
        }), operators_1.map(function (payload) { return new project_reducer_1.ProjectTaskCommentCreated(payload); }));
        this.updateTaskComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_COMMENT_UPDATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, task = _b.task, payload = _b.comment;
            return _this.http
                .put(payload.url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ task: task, comment: comment });
            }));
        }), operators_1.map(function (payload) { return new project_reducer_1.ProjectTaskCommentUpdated(payload); }));
        this.deleteTaskComment$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_COMMENT_REMOVE'), operators_1.delayWhen(function (_a) {
            var comment = _a.payload.comment;
            return _this.http.delete(comment.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectTaskCommentRemoved(payload);
        }));
        this.attachDocumentFromLibrary$ = this.actions$.pipe(rx_1.ofType('PROJECT_ATTACHMENT_ATTACH_FROM_LIBRARY'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var payload = _a[0].payload, project = _a[1];
            return _this.http.post(project.library_attachments_url, {
                document_id: payload.id,
            });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectAttachmentCreated(payload);
        }));
        this.refreshTeam$ = this.actions$.pipe(rx_1.ofType('PROJECT_TASK_CREATED', 'PROJECT_TASK_UPDATED'), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectLoad(payload.project_id);
        }));
        this.removeMember$ = this.actions$.pipe(rx_1.ofType('PROJECT_MEMBER_REMOVE'), operators_1.delayWhen(function (_a) {
            var payload = _a.payload;
            return _this.http.delete(payload.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectMemberRemoved(payload);
        }));
        this.assignUser$ = this.actions$.pipe(rx_1.ofType('PROJECT_ASSIGN'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var payload = _a[0].payload, project = _a[1];
            return _this.http.put(project.team_assignee_url, { user_id: payload.id });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectUpdated(payload);
        }));
        this.stateUpdate$ = this.actions$.pipe(rx_1.ofType('PROJECT_UPDATE_STATE'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var data = _a[0].payload, project = _a[1];
            return _this.http.put(project.state_url, data).pipe(operators_1.mergeMap(function (_a) {
                var payload = _a.data;
                return [
                    new project_reducer_1.ProjectUpdated(payload),
                    new project_reducer_1.ProjectUpdateStateDialogSuccess(),
                ];
            }), operators_1.catchError(function (response) {
                if (response.status == 412) {
                    return rxjs_1.of({
                        type: 'PROJECT_UPDATE_STATE_DIALOG',
                        payload: response.error.data,
                    });
                }
                if (response.status == 422) {
                    return rxjs_1.of(new project_reducer_1.ProjectUpdateStateDialogFailed(ErrorBag_1.ErrorBag.fromResponse(response)));
                }
                return rxjs_1.throwError(response);
            }));
        }));
        this.attachmentRemove$ = this.actions$.pipe(rx_1.ofType('PROJECT_ATTACHMENT_REMOVE'), operators_1.delayWhen(function (_a) {
            var document = _a.payload;
            return _this.http.delete(document.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectAttachmentRemoved(payload);
        }));
        this.tagAttach$ = this.actions$.pipe(rx_1.ofType('PROJECT_TAG_ATTACH'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var tag = _a[0].payload, project = _a[1];
            return _this.http.post(project.url + "/tags/" + tag.id, {});
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectUpdated(payload);
        }));
        this.tagDetach$ = this.actions$.pipe(rx_1.ofType('PROJECT_TAG_DETACH'), operators_1.withLatestFrom(this.store.pipe(rx_1.choose(function (x) { return x.project; }))), operators_1.mergeMap(function (_a) {
            var tag = _a[0].payload, project = _a[1];
            return _this.http.delete(project.url + "/tags/" + tag.id);
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new project_reducer_1.ProjectUpdated(payload);
        }));
        this.documentVersionCommentCreate$ = this.actions$.pipe(rx_1.ofType('PROJECT_ATTACHMENT_VERSION_COMMENT_CREATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, payload = _b.comment, version = _b.version, attachment = _b.attachment;
            return _this.http
                .post(version.comments_url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ comment: comment, version: version, attachment: attachment });
            }));
        }), operators_1.map(function (payload) { return new project_reducer_1.ProjectAttachmentVersionCommentCreated(payload); }));
        this.documentVersionCommentUpdate$ = this.actions$.pipe(rx_1.ofType('PROJECT_ATTACHMENT_VERSION_COMMENT_UPDATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, payload = _b.comment, version = _b.version, attachment = _b.attachment;
            return _this.http
                .put(payload.url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ attachment: attachment, version: version, comment: comment });
            }));
        }), operators_1.map(function (payload) { return new project_reducer_1.ProjectAttachmentVersionCommentUpdated(payload); }));
        this.documentVersionCommentRemove$ = this.actions$.pipe(rx_1.ofType('PROJECT_ATTACHMENT_VERSION_COMMENT_REMOVE'), operators_1.delayWhen(function (_a) {
            var comment = _a.payload.comment;
            return _this.http.delete(comment.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new project_reducer_1.ProjectAttachmentVersionCommentRemoved(payload);
        }));
        //
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "loadProject$", void 0);
    __decorate([
        effects_1.Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "projectNotFound$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "updateProject$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "openTask$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "resolveTask$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "logRefreshRequested$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "createComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "updateComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "removeComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "createCommentReply$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "updateCommentReply$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "removeCommentReply$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "createTaskComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "updateTaskComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "deleteTaskComment$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "attachDocumentFromLibrary$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "refreshTeam$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "removeMember$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "assignUser$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "stateUpdate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "attachmentRemove$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "tagAttach$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "tagDetach$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "documentVersionCommentCreate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "documentVersionCommentUpdate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ProjectEffects.prototype, "documentVersionCommentRemove$", void 0);
    return ProjectEffects;
}());
exports.ProjectEffects = ProjectEffects;
