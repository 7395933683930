"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Response_1 = require("@utils/Response");
var array_1 = require("@utils/array");
function reset(reducer) {
    return function (state, action) {
        if (action.type === 'RESET') {
            state = undefined;
        }
        return reducer(state, action);
    };
}
exports.reset = reset;
var SignedInLoaded = /** @class */ (function () {
    function SignedInLoaded(payload) {
        this.payload = payload;
        this.type = 'SIGNED_IN_LOADED';
        //
    }
    return SignedInLoaded;
}());
exports.SignedInLoaded = SignedInLoaded;
var SignedInTagsUpdated = /** @class */ (function () {
    function SignedInTagsUpdated(payload) {
        this.payload = payload;
        this.type = 'SIGNED_IN_TAGS_UPDATED';
        //
    }
    return SignedInTagsUpdated;
}());
exports.SignedInTagsUpdated = SignedInTagsUpdated;
var SignedInUpdated = /** @class */ (function () {
    function SignedInUpdated(payload) {
        this.payload = payload;
        this.type = 'SIGNED_IN_UPDATED';
        //
    }
    return SignedInUpdated;
}());
exports.SignedInUpdated = SignedInUpdated;
function signedIn(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case 'SIGNED_IN_LOADED':
            return action.payload;
        case 'SIGNED_IN_TAGS_UPDATED':
            if (!state)
                return null;
            return __assign({}, state, { tags: array_1.not(state.tags, { id: action.payload.id }).concat(action.payload) });
        case 'SIGNED_IN_UPDATED':
            if (!state)
                return null;
            return __assign({}, state, action.payload);
        default:
            return state;
    }
}
exports.signedIn = signedIn;
var TemplateLoaded = /** @class */ (function () {
    function TemplateLoaded(payload) {
        this.payload = payload;
        this.type = 'TEMPLATE_LOADED';
        //
    }
    return TemplateLoaded;
}());
exports.TemplateLoaded = TemplateLoaded;
function template(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'TEMPLATE_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.template = template;
var RolesLoaded = /** @class */ (function () {
    function RolesLoaded(payload) {
        this.payload = payload;
        this.type = 'ROLES_LOADED';
        //
    }
    return RolesLoaded;
}());
exports.RolesLoaded = RolesLoaded;
var StatesLoaded = /** @class */ (function () {
    function StatesLoaded(payload) {
        this.payload = payload;
        this.type = 'STATES_LOADED';
        //
    }
    return StatesLoaded;
}());
exports.StatesLoaded = StatesLoaded;
function roles(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'ROLES_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.roles = roles;
var TranslationsLoad = /** @class */ (function () {
    function TranslationsLoad() {
        this.type = 'TRANSLATIONS_LOAD';
    }
    return TranslationsLoad;
}());
exports.TranslationsLoad = TranslationsLoad;
var TranslationsLoaded = /** @class */ (function () {
    function TranslationsLoaded(payload) {
        this.payload = payload;
        this.type = 'TRANSLATIONS_LOADED';
        //
    }
    return TranslationsLoaded;
}());
exports.TranslationsLoaded = TranslationsLoaded;
function translations(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case 'TRANSLATIONS_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.translations = translations;
var SearchLoad = /** @class */ (function () {
    function SearchLoad(payload) {
        this.payload = payload;
        this.type = 'SEARCH_LOAD';
        //
    }
    return SearchLoad;
}());
exports.SearchLoad = SearchLoad;
var SearchLoaded = /** @class */ (function () {
    function SearchLoaded(payload) {
        this.payload = payload;
        this.type = 'SEARCH_LOADED';
        //
    }
    return SearchLoaded;
}());
exports.SearchLoaded = SearchLoaded;
var SearchClear = /** @class */ (function () {
    function SearchClear() {
        this.type = 'SEARCH_CLEAR';
    }
    return SearchClear;
}());
exports.SearchClear = SearchClear;
function search(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'SEARCH_LOADED':
            return action.payload;
        case 'SEARCH_CLEAR':
            return [];
        default:
            return state;
    }
}
exports.search = search;
var ReportsLoad = /** @class */ (function () {
    function ReportsLoad() {
        this.type = 'REPORTS_LOAD';
    }
    return ReportsLoad;
}());
exports.ReportsLoad = ReportsLoad;
var ReportsLoaded = /** @class */ (function () {
    function ReportsLoaded(payload) {
        this.payload = payload;
        this.type = 'REPORTS_LOADED';
        //
    }
    return ReportsLoaded;
}());
exports.ReportsLoaded = ReportsLoaded;
function reports(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'REPORTS_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.reports = reports;
var AuditsLoad = /** @class */ (function () {
    function AuditsLoad(payload) {
        this.payload = payload;
        this.type = 'AUDITS_LOAD';
        //
    }
    return AuditsLoad;
}());
exports.AuditsLoad = AuditsLoad;
var AuditsLoaded = /** @class */ (function () {
    function AuditsLoaded(payload) {
        this.payload = payload;
        this.type = 'AUDITS_LOADED';
        //
    }
    return AuditsLoaded;
}());
exports.AuditsLoaded = AuditsLoaded;
function audits(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'AUDITS_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.audits = audits;
var DashboardLoad = /** @class */ (function () {
    function DashboardLoad(payload) {
        this.payload = payload;
        this.type = 'DASHBOARD_LOAD';
        //
    }
    return DashboardLoad;
}());
exports.DashboardLoad = DashboardLoad;
var DashboardLoaded = /** @class */ (function () {
    function DashboardLoaded(payload) {
        this.payload = payload;
        this.type = 'DASHBOARD_LOADED';
        //
    }
    return DashboardLoaded;
}());
exports.DashboardLoaded = DashboardLoaded;
var DashboardProjectUpdated = /** @class */ (function () {
    function DashboardProjectUpdated(payload) {
        this.payload = payload;
        this.type = 'DASHBOARD_PROJECT_UPDATED';
        //
    }
    return DashboardProjectUpdated;
}());
exports.DashboardProjectUpdated = DashboardProjectUpdated;
function dashboard(state, action) {
    if (state === void 0) { state = Response_1.empty(); }
    switch (action.type) {
        case 'DASHBOARD_LOADED':
            return action.payload;
        case 'DASHBOARD_PROJECT_UPDATED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.id }).concat([action.payload]) });
        default:
            return state;
    }
}
exports.dashboard = dashboard;
var CalendarLoad = /** @class */ (function () {
    function CalendarLoad() {
        this.type = 'CALENDAR_LOAD';
        //
    }
    return CalendarLoad;
}());
exports.CalendarLoad = CalendarLoad;
var CalendarLoaded = /** @class */ (function () {
    function CalendarLoaded(payload) {
        this.payload = payload;
        this.type = 'CALENDAR_LOADED';
        //
    }
    return CalendarLoaded;
}());
exports.CalendarLoaded = CalendarLoaded;
function calendar(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'CALENDAR_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.calendar = calendar;
var ReportDetailLoad = /** @class */ (function () {
    function ReportDetailLoad(payload) {
        this.payload = payload;
        this.type = 'REPORT_DETAIL_LOAD';
        //
    }
    return ReportDetailLoad;
}());
exports.ReportDetailLoad = ReportDetailLoad;
var ReportDetailLoaded = /** @class */ (function () {
    function ReportDetailLoaded(payload) {
        this.payload = payload;
        this.type = 'REPORT_DETAIL_LOADED';
        //
    }
    return ReportDetailLoaded;
}());
exports.ReportDetailLoaded = ReportDetailLoaded;
var ReportDetailClear = /** @class */ (function () {
    function ReportDetailClear() {
        this.type = 'REPORT_DETAIL_CLEAR';
    }
    return ReportDetailClear;
}());
exports.ReportDetailClear = ReportDetailClear;
function reportDetail(state, action) {
    if (state === void 0) { state = Response_1.empty(); }
    switch (action.type) {
        case 'REPORT_DETAIL_LOADED':
            return action.payload;
        case 'REPORT_DETAIL_CLEAR':
            return Response_1.empty();
        default:
            return state;
    }
}
exports.reportDetail = reportDetail;
var NavbarLoaded = /** @class */ (function () {
    function NavbarLoaded(payload) {
        this.payload = payload;
        this.type = 'NAVBAR_LOADED';
        //
    }
    return NavbarLoaded;
}());
exports.NavbarLoaded = NavbarLoaded;
function navbar(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'NAVBAR_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.navbar = navbar;
var KindsLoaded = /** @class */ (function () {
    function KindsLoaded(payload) {
        this.payload = payload;
        this.type = 'KINDS_LOADED';
        //
    }
    return KindsLoaded;
}());
exports.KindsLoaded = KindsLoaded;
function kinds(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'KINDS_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.kinds = kinds;
function integration(state, action) {
    if (state === void 0) { state = ''; }
    switch (action.type) {
        case 'INTEGRATION_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.integration = integration;
var TimezonesLoaded = /** @class */ (function () {
    function TimezonesLoaded(payload) {
        this.payload = payload;
        this.type = 'TIMEZONES_LOADED';
        //
    }
    return TimezonesLoaded;
}());
exports.TimezonesLoaded = TimezonesLoaded;
var IntegrationLoaded = /** @class */ (function () {
    function IntegrationLoaded(payload) {
        this.payload = payload;
        this.type = 'INTEGRATION_LOADED';
        //
    }
    return IntegrationLoaded;
}());
exports.IntegrationLoaded = IntegrationLoaded;
function timezones(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'TIMEZONES_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.timezones = timezones;
var ConfigurationLoaded = /** @class */ (function () {
    function ConfigurationLoaded(payload) {
        this.payload = payload;
        this.type = 'CONFIGURATION_LOADED';
        //
    }
    return ConfigurationLoaded;
}());
exports.ConfigurationLoaded = ConfigurationLoaded;
var ExportCreate = /** @class */ (function () {
    function ExportCreate(payload) {
        this.payload = payload;
        this.type = 'EXPORT_CREATE';
        //
    }
    return ExportCreate;
}());
exports.ExportCreate = ExportCreate;
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = 'LOGOUT';
    }
    return Logout;
}());
exports.Logout = Logout;
var DashboardProjectTagAttach = /** @class */ (function () {
    function DashboardProjectTagAttach(payload) {
        this.payload = payload;
        this.type = 'DASHBOARD_PROJECT_TAG_ATTACH';
        //
    }
    return DashboardProjectTagAttach;
}());
exports.DashboardProjectTagAttach = DashboardProjectTagAttach;
var DashboardProjectTagDetach = /** @class */ (function () {
    function DashboardProjectTagDetach(payload) {
        this.payload = payload;
        this.type = 'DASHBOARD_PROJECT_TAG_DETACH';
        //
    }
    return DashboardProjectTagDetach;
}());
exports.DashboardProjectTagDetach = DashboardProjectTagDetach;
