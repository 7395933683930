"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var SsoFirstTimeService = /** @class */ (function () {
    function SsoFirstTimeService(router) {
        this.router = router;
        //
    }
    SsoFirstTimeService.prototype.handle = function () {
        var firstSso = localStorage.getItem('firstSso');
        if (firstSso === "1") {
            localStorage.setItem('firstSsoConfirmed', "1");
            localStorage.removeItem('firstSso');
            this.router.navigate(['', { outlets: { secondary: ['sso-first-time'] } }]);
        }
    };
    return SsoFirstTimeService;
}());
exports.SsoFirstTimeService = SsoFirstTimeService;
