"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var Checksum_service_1 = require("./Checksum.service");
var url_1 = require("@utils/url");
var SsoFirstTime_service_1 = require("@services/SsoFirstTime.service");
var OdsFirstTime_service_1 = require("@services/OdsFirstTime.service");
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(checksum, sso, ods) {
        this.checksum = checksum;
        this.sso = sso;
        this.ods = ods;
        //
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var headers = request.headers
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
        return next
            .handle(request.clone({
            headers: headers,
            url: url_1.pretty(request.urlWithParams),
            params: new http_1.HttpParams(),
        }))
            .pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                _this.sso.handle();
                _this.ods.handle();
                _this.checksum.handle(event);
            }
        }));
    };
    return AuthInterceptor;
}());
exports.AuthInterceptor = AuthInterceptor;
