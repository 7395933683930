"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Auth_middleware_1 = require("@services/Auth.middleware");
var NotFound_component_1 = require("./components/NotFound.component");
var NewVersionAvailable_component_1 = require("./components/NewVersionAvailable.component");
var SsoFirstTime_component_1 = require("./components/SsoFirstTime.component");
var OdsFirstTime_component_1 = require("./components/OdsFirstTime.component");
var ConfirmModal_component_1 = require("@modules/Support/ConfirmModal.component");
var routes = [
    { path: '', loadChildren: './pages/pages.module#PagesModule', canActivate: [Auth_middleware_1.AuthMiddleware] },
    { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
    { path: 'new-version', component: NewVersionAvailable_component_1.NewVersionAvailableComponent, outlet: 'secondary' },
    { path: 'sso-first-time', component: SsoFirstTime_component_1.SsoFirstTimeComponent, outlet: 'secondary' },
    { path: 'ods-first-time', component: OdsFirstTime_component_1.OdsFirstTimeComponent, outlet: 'secondary' },
    { path: 'leave-guard', component: ConfirmModal_component_1.ConfirmModalComponent, outlet: 'secondary' },
    { path: '**', component: NotFound_component_1.NotFoundComponent },
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
