"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var operators_1 = require("rxjs/operators");
var OdsFirstTimeComponent = /** @class */ (function () {
    function OdsFirstTimeComponent(router, store) {
        this.router = router;
        this.store = store;
        this.signedIn$ = this.store.pipe(rx_1.choose(function (x) { return x.signedIn; }));
        this.links$ = this.store.pipe(rx_1.choose(function (x) { return x.navbar; }), operators_1.map(function (links) { return links.map(function (link) { return (__assign({}, link, { url: sanitize(link.url) })); }).filter(function (link) { return link.label === 'News'; }); }));
        //
    }
    OdsFirstTimeComponent.prototype.reload = function () {
        this.router
            .navigate(['', { outlets: { secondary: null } }])
            .then(function () {
        });
    };
    return OdsFirstTimeComponent;
}());
exports.OdsFirstTimeComponent = OdsFirstTimeComponent;
function sanitize(url) {
    return url
        .replace('http://', '')
        .replace('https://', '')
        .replace(window.location.host + "/#", '');
}
