"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var OdsFirstTimeService = /** @class */ (function () {
    function OdsFirstTimeService(router) {
        this.router = router;
        //
    }
    OdsFirstTimeService.prototype.handle = function () {
        var firstOds = localStorage.getItem('firstOdsConfirmed');
        console.log('firstOds', firstOds);
        if (firstOds !== "1") {
            localStorage.setItem('firstOdsConfirmed', "1");
            this.router.navigate(['', { outlets: { secondary: ['ods-first-time'] } }]);
        }
    };
    return OdsFirstTimeService;
}());
exports.OdsFirstTimeService = OdsFirstTimeService;
