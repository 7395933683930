"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./Icon.component");
var styles_IconComponent = ["[_nghost-%COMP%] { display: inline-block; }"];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_IconComponent, data: {} });
exports.RenderType_IconComponent = RenderType_IconComponent;
function View_IconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "block fill-current"]], [[2, "w-4", null], [2, "h-4", null], [2, "w-6", null], [2, "h-6", null], [2, "w-8", null], [2, "h-8", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0], [1, "href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size == 4); var currVal_1 = (_co.size == 4); var currVal_2 = (_co.size == 6); var currVal_3 = (_co.size == 6); var currVal_4 = (_co.size == 8); var currVal_5 = (_co.size == 8); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.identifier; var currVal_7 = _co.identifier; _ck(_v, 1, 0, currVal_6, currVal_7); }); }
exports.View_IconComponent_0 = View_IconComponent_0;
function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i1.IconComponent, [], null, null)], null, null); }
exports.View_IconComponent_Host_0 = View_IconComponent_Host_0;
var IconComponentNgFactory = i0.ɵccf("icon", i1.IconComponent, View_IconComponent_Host_0, { size: "size", name: "name" }, {}, []);
exports.IconComponentNgFactory = IconComponentNgFactory;
