"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var general_1 = require("@utils/general");
var array_1 = require("@utils/array");
var ProjectLoad = /** @class */ (function () {
    function ProjectLoad(payload) {
        this.payload = payload;
        this.type = 'PROJECT_LOAD';
        //
    }
    return ProjectLoad;
}());
exports.ProjectLoad = ProjectLoad;
var ProjectLoaded = /** @class */ (function () {
    function ProjectLoaded(payload) {
        this.payload = payload;
        this.type = 'PROJECT_LOADED';
        //
    }
    return ProjectLoaded;
}());
exports.ProjectLoaded = ProjectLoaded;
var ProjectUpdate = /** @class */ (function () {
    function ProjectUpdate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_UPDATE';
        //
    }
    return ProjectUpdate;
}());
exports.ProjectUpdate = ProjectUpdate;
var ProjectUpdated = /** @class */ (function () {
    function ProjectUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_UPDATED';
        //
    }
    return ProjectUpdated;
}());
exports.ProjectUpdated = ProjectUpdated;
var ProjectTaskCreated = /** @class */ (function () {
    function ProjectTaskCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_CREATED';
        //
    }
    return ProjectTaskCreated;
}());
exports.ProjectTaskCreated = ProjectTaskCreated;
var ProjectTaskUpdated = /** @class */ (function () {
    function ProjectTaskUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_UPDATED';
        //
    }
    return ProjectTaskUpdated;
}());
exports.ProjectTaskUpdated = ProjectTaskUpdated;
var ProjectTaskOpen = /** @class */ (function () {
    function ProjectTaskOpen(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_OPEN';
        //
    }
    return ProjectTaskOpen;
}());
exports.ProjectTaskOpen = ProjectTaskOpen;
var ProjectTaskOpened = /** @class */ (function () {
    function ProjectTaskOpened(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_OPENED';
        //
    }
    return ProjectTaskOpened;
}());
exports.ProjectTaskOpened = ProjectTaskOpened;
var ProjectTaskResolve = /** @class */ (function () {
    function ProjectTaskResolve(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_RESOLVE';
        //
    }
    return ProjectTaskResolve;
}());
exports.ProjectTaskResolve = ProjectTaskResolve;
var ProjectTaskResolved = /** @class */ (function () {
    function ProjectTaskResolved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_RESOLVED';
        //
    }
    return ProjectTaskResolved;
}());
exports.ProjectTaskResolved = ProjectTaskResolved;
var ProjectTaskCommentCreate = /** @class */ (function () {
    function ProjectTaskCommentCreate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_CREATE';
        //
    }
    return ProjectTaskCommentCreate;
}());
exports.ProjectTaskCommentCreate = ProjectTaskCommentCreate;
var ProjectTaskCommentCreated = /** @class */ (function () {
    function ProjectTaskCommentCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_CREATED';
        //
    }
    return ProjectTaskCommentCreated;
}());
exports.ProjectTaskCommentCreated = ProjectTaskCommentCreated;
var ProjectTaskCommentUpdate = /** @class */ (function () {
    function ProjectTaskCommentUpdate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_UPDATE';
        //
    }
    return ProjectTaskCommentUpdate;
}());
exports.ProjectTaskCommentUpdate = ProjectTaskCommentUpdate;
var ProjectTaskCommentUpdated = /** @class */ (function () {
    function ProjectTaskCommentUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_UPDATED';
        //
    }
    return ProjectTaskCommentUpdated;
}());
exports.ProjectTaskCommentUpdated = ProjectTaskCommentUpdated;
var ProjectTaskCommentRemove = /** @class */ (function () {
    function ProjectTaskCommentRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_REMOVE';
        //
    }
    return ProjectTaskCommentRemove;
}());
exports.ProjectTaskCommentRemove = ProjectTaskCommentRemove;
var ProjectTaskCommentRemoved = /** @class */ (function () {
    function ProjectTaskCommentRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TASK_COMMENT_REMOVED';
        //
    }
    return ProjectTaskCommentRemoved;
}());
exports.ProjectTaskCommentRemoved = ProjectTaskCommentRemoved;
var ProjectCommentCreate = /** @class */ (function () {
    function ProjectCommentCreate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_CREATE';
        //
    }
    return ProjectCommentCreate;
}());
exports.ProjectCommentCreate = ProjectCommentCreate;
var ProjectCommentCreated = /** @class */ (function () {
    function ProjectCommentCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_CREATED';
        //
    }
    return ProjectCommentCreated;
}());
exports.ProjectCommentCreated = ProjectCommentCreated;
var ProjectCommentUpdate = /** @class */ (function () {
    function ProjectCommentUpdate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_UPDATE';
        //
    }
    return ProjectCommentUpdate;
}());
exports.ProjectCommentUpdate = ProjectCommentUpdate;
var ProjectCommentUpdated = /** @class */ (function () {
    function ProjectCommentUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_UPDATED';
        //
    }
    return ProjectCommentUpdated;
}());
exports.ProjectCommentUpdated = ProjectCommentUpdated;
var ProjectCommentRemove = /** @class */ (function () {
    function ProjectCommentRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REMOVE';
        //
    }
    return ProjectCommentRemove;
}());
exports.ProjectCommentRemove = ProjectCommentRemove;
var ProjectCommentRemoved = /** @class */ (function () {
    function ProjectCommentRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REMOVED';
        //
    }
    return ProjectCommentRemoved;
}());
exports.ProjectCommentRemoved = ProjectCommentRemoved;
var ProjectCommentReplyCreate = /** @class */ (function () {
    function ProjectCommentReplyCreate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_CREATE';
        //
    }
    return ProjectCommentReplyCreate;
}());
exports.ProjectCommentReplyCreate = ProjectCommentReplyCreate;
var ProjectCommentReplyCreated = /** @class */ (function () {
    function ProjectCommentReplyCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_CREATED';
        //
    }
    return ProjectCommentReplyCreated;
}());
exports.ProjectCommentReplyCreated = ProjectCommentReplyCreated;
var ProjectCommentReplyUpdate = /** @class */ (function () {
    function ProjectCommentReplyUpdate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_UPDATE';
        //
    }
    return ProjectCommentReplyUpdate;
}());
exports.ProjectCommentReplyUpdate = ProjectCommentReplyUpdate;
var ProjectCommentReplyUpdated = /** @class */ (function () {
    function ProjectCommentReplyUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_UPDATED';
        //
    }
    return ProjectCommentReplyUpdated;
}());
exports.ProjectCommentReplyUpdated = ProjectCommentReplyUpdated;
var ProjectCommentReplyRemove = /** @class */ (function () {
    function ProjectCommentReplyRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_REMOVE';
        //
    }
    return ProjectCommentReplyRemove;
}());
exports.ProjectCommentReplyRemove = ProjectCommentReplyRemove;
var ProjectCommentReplyRemoved = /** @class */ (function () {
    function ProjectCommentReplyRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_COMMENT_REPLY_REMOVED';
        //
    }
    return ProjectCommentReplyRemoved;
}());
exports.ProjectCommentReplyRemoved = ProjectCommentReplyRemoved;
var ProjectMemberCreated = /** @class */ (function () {
    function ProjectMemberCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_MEMBER_CREATED';
        //
    }
    return ProjectMemberCreated;
}());
exports.ProjectMemberCreated = ProjectMemberCreated;
var ProjectMemberRemove = /** @class */ (function () {
    function ProjectMemberRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_MEMBER_REMOVE';
        //
    }
    return ProjectMemberRemove;
}());
exports.ProjectMemberRemove = ProjectMemberRemove;
var ProjectMemberRemoved = /** @class */ (function () {
    function ProjectMemberRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_MEMBER_REMOVED';
        //
    }
    return ProjectMemberRemoved;
}());
exports.ProjectMemberRemoved = ProjectMemberRemoved;
var ProjectAttachmentCreated = /** @class */ (function () {
    function ProjectAttachmentCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_CREATED';
        //
    }
    return ProjectAttachmentCreated;
}());
exports.ProjectAttachmentCreated = ProjectAttachmentCreated;
var ProjectAttachmentUpdated = /** @class */ (function () {
    function ProjectAttachmentUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_UPDATED';
        //
    }
    return ProjectAttachmentUpdated;
}());
exports.ProjectAttachmentUpdated = ProjectAttachmentUpdated;
var ProjectAttachmentRemove = /** @class */ (function () {
    function ProjectAttachmentRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_REMOVE';
        //
    }
    return ProjectAttachmentRemove;
}());
exports.ProjectAttachmentRemove = ProjectAttachmentRemove;
var ProjectAttachmentRemoved = /** @class */ (function () {
    function ProjectAttachmentRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_REMOVED';
        //
    }
    return ProjectAttachmentRemoved;
}());
exports.ProjectAttachmentRemoved = ProjectAttachmentRemoved;
var ProjectAttachmentVersionCommentCreate = /** @class */ (function () {
    function ProjectAttachmentVersionCommentCreate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_CREATE';
        //
    }
    return ProjectAttachmentVersionCommentCreate;
}());
exports.ProjectAttachmentVersionCommentCreate = ProjectAttachmentVersionCommentCreate;
var ProjectAttachmentVersionCommentCreated = /** @class */ (function () {
    function ProjectAttachmentVersionCommentCreated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_CREATED';
        //
    }
    return ProjectAttachmentVersionCommentCreated;
}());
exports.ProjectAttachmentVersionCommentCreated = ProjectAttachmentVersionCommentCreated;
var ProjectAttachmentVersionCommentUpdate = /** @class */ (function () {
    function ProjectAttachmentVersionCommentUpdate(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_UPDATE';
        //
    }
    return ProjectAttachmentVersionCommentUpdate;
}());
exports.ProjectAttachmentVersionCommentUpdate = ProjectAttachmentVersionCommentUpdate;
var ProjectAttachmentVersionCommentUpdated = /** @class */ (function () {
    function ProjectAttachmentVersionCommentUpdated(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_UPDATED';
        //
    }
    return ProjectAttachmentVersionCommentUpdated;
}());
exports.ProjectAttachmentVersionCommentUpdated = ProjectAttachmentVersionCommentUpdated;
var ProjectAttachmentVersionCommentRemove = /** @class */ (function () {
    function ProjectAttachmentVersionCommentRemove(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_REMOVE';
        //
    }
    return ProjectAttachmentVersionCommentRemove;
}());
exports.ProjectAttachmentVersionCommentRemove = ProjectAttachmentVersionCommentRemove;
var ProjectAttachmentVersionCommentRemoved = /** @class */ (function () {
    function ProjectAttachmentVersionCommentRemoved(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_VERSION_COMMENT_REMOVED';
        //
    }
    return ProjectAttachmentVersionCommentRemoved;
}());
exports.ProjectAttachmentVersionCommentRemoved = ProjectAttachmentVersionCommentRemoved;
var ProjectAttachmentAttachFromLibrary = /** @class */ (function () {
    function ProjectAttachmentAttachFromLibrary(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ATTACHMENT_ATTACH_FROM_LIBRARY';
        //
    }
    return ProjectAttachmentAttachFromLibrary;
}());
exports.ProjectAttachmentAttachFromLibrary = ProjectAttachmentAttachFromLibrary;
var ProjectTagAttach = /** @class */ (function () {
    function ProjectTagAttach(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TAG_ATTACH';
        //
    }
    return ProjectTagAttach;
}());
exports.ProjectTagAttach = ProjectTagAttach;
var ProjectTagDetach = /** @class */ (function () {
    function ProjectTagDetach(payload) {
        this.payload = payload;
        this.type = 'PROJECT_TAG_DETACH';
        //
    }
    return ProjectTagDetach;
}());
exports.ProjectTagDetach = ProjectTagDetach;
var ProjectAssign = /** @class */ (function () {
    function ProjectAssign(payload) {
        this.payload = payload;
        this.type = 'PROJECT_ASSIGN';
        //
    }
    return ProjectAssign;
}());
exports.ProjectAssign = ProjectAssign;
var ProjectUpdateStateDialogSuccess = /** @class */ (function () {
    function ProjectUpdateStateDialogSuccess() {
        this.type = 'PROJECT_UPDATE_STATE_DIALOG_SUCCESS';
    }
    return ProjectUpdateStateDialogSuccess;
}());
exports.ProjectUpdateStateDialogSuccess = ProjectUpdateStateDialogSuccess;
var ProjectUpdateState = /** @class */ (function () {
    function ProjectUpdateState(payload) {
        this.payload = payload;
        this.type = 'PROJECT_UPDATE_STATE';
        //
    }
    return ProjectUpdateState;
}());
exports.ProjectUpdateState = ProjectUpdateState;
var ProjectUpdateStateDialog = /** @class */ (function () {
    function ProjectUpdateStateDialog(payload) {
        this.payload = payload;
        this.type = 'PROJECT_UPDATE_STATE_DIALOG';
        //
    }
    return ProjectUpdateStateDialog;
}());
exports.ProjectUpdateStateDialog = ProjectUpdateStateDialog;
var ProjectUpdateStateDialogFailed = /** @class */ (function () {
    function ProjectUpdateStateDialogFailed(payload) {
        this.payload = payload;
        this.type = 'PROJECT_UPDATE_STATE_DIALOG_FAILED';
        //
    }
    return ProjectUpdateStateDialogFailed;
}());
exports.ProjectUpdateStateDialogFailed = ProjectUpdateStateDialogFailed;
var ProjectLogsRefreshed = /** @class */ (function () {
    function ProjectLogsRefreshed(payload) {
        this.payload = payload;
        this.type = 'PROJECT_LOGS_REFRESHED';
        //
    }
    return ProjectLogsRefreshed;
}());
exports.ProjectLogsRefreshed = ProjectLogsRefreshed;
var ProjectClear = /** @class */ (function () {
    function ProjectClear() {
        this.type = 'PROJECT_CLEAR';
    }
    return ProjectClear;
}());
exports.ProjectClear = ProjectClear;
var ProjectNotFound = /** @class */ (function () {
    function ProjectNotFound() {
        this.type = 'PROJECT_NOT_FOUND';
    }
    return ProjectNotFound;
}());
exports.ProjectNotFound = ProjectNotFound;
function project(state, action) {
    var _a, _b;
    if (state === void 0) { state = null; }
    switch (action.type) {
        case 'PROJECT_LOADED':
        case 'PROJECT_UPDATED':
            return action.payload;
        case 'PROJECT_TASK_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { tasks: state.tasks.concat([action.payload]) });
        case 'PROJECT_TASK_UPDATED':
        case 'PROJECT_TASK_OPENED':
        case 'PROJECT_TASK_RESOLVED':
            if (!state)
                return null;
            return __assign({}, state, { tasks: array_1.not(state.tasks, { id: action.payload.id }).concat([action.payload]) });
        case 'PROJECT_TASK_COMMENT_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { tasks: array_1.not(state.tasks, { id: action.payload.task.id }).concat([
                    __assign({}, action.payload.task, { comments: action.payload.task.comments.concat([action.payload.comment]) }),
                ]) });
        case 'PROJECT_TASK_COMMENT_UPDATED':
            if (!state)
                return null;
            return general_1.tap(array_1.firstOrFail(array_1.where(state.tasks, { id: action.payload.task.id })), function (task) { return (__assign({}, state, { tasks: array_1.not(state.tasks, { id: action.payload.task.id }).concat([
                    __assign({}, task, { comments: array_1.not(task.comments, { id: action.payload.comment.id }).concat([
                            action.payload.comment,
                        ]) }),
                ]) })); });
        case 'PROJECT_TASK_COMMENT_REMOVED':
            if (!state)
                return null;
            return general_1.tap(array_1.firstOrFail(array_1.where(state.tasks, { id: action.payload.task.id })), function (task) { return (__assign({}, state, { tasks: array_1.not(state.tasks, { id: action.payload.task.id }).concat([
                    __assign({}, task, { comments: array_1.not(task.comments, { id: action.payload.comment.id }).slice() }),
                ]) })); });
        case 'PROJECT_COMMENT_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { comments: state.comments.concat([action.payload]) });
        case 'PROJECT_COMMENT_UPDATED':
            if (!state)
                return null;
            return __assign({}, state, { comments: array_1.not(state.comments, { id: action.payload.id }).concat([action.payload]) });
        case 'PROJECT_COMMENT_REMOVED':
            if (!state)
                return null;
            return __assign({}, state, { comments: array_1.not(state.comments, { id: action.payload.id }) });
        case 'PROJECT_COMMENT_REPLY_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { comments: state.comments.concat([action.payload]) });
        case 'PROJECT_COMMENT_REPLY_UPDATED':
            if (!state)
                return null;
            return general_1.tap(action.payload.parent_id, function (parent_id) {
                if (parent_id == null)
                    return state;
                return general_1.tap(array_1.firstOrFail(array_1.where(state.comments, { id: parent_id })), function (c) { return (__assign({}, state, { comments: array_1.not(state.comments, { id: parent_id }).concat([
                        __assign({}, c, { comments: array_1.not(c.comments, { id: action.payload.id }).concat([
                                action.payload,
                            ]) }),
                    ]) })); });
            });
        case 'PROJECT_COMMENT_REPLY_REMOVED':
            if (!state)
                return null;
            return general_1.tap(action.payload.parent_id, function (parent_id) {
                if (parent_id == null)
                    return state;
                return general_1.tap(array_1.firstOrFail(array_1.where(state.comments, { id: parent_id })), function (c) { return (__assign({}, state, { comments: array_1.not(state.comments, { id: parent_id }).concat([
                        __assign({}, c, { comments: array_1.not(c.comments, { id: action.payload.id }).slice() }),
                    ]) })); });
            });
        case 'PROJECT_MEMBER_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { team: __assign({}, state.team, { multiple: __assign({}, state.team.multiple, (_a = {}, _a[action.payload.role] = state.team.multiple[action.payload.role].concat([
                        action.payload,
                    ]), _a)) }) });
        case 'PROJECT_MEMBER_REMOVED':
            if (!state)
                return null;
            return __assign({}, state, { team: __assign({}, state.team, { multiple: __assign({}, state.team.multiple, (_b = {}, _b[action.payload.role] = array_1.not(state.team.multiple[action.payload.role], {
                        id: action.payload.id,
                    }), _b)) }) });
        case 'PROJECT_ATTACHMENT_CREATED':
            if (!state)
                return null;
            return __assign({}, state, { attachments: state.attachments.concat([action.payload]) });
        case 'PROJECT_ATTACHMENT_UPDATED':
            if (!state)
                return null;
            return __assign({}, state, { attachments: array_1.not(state.attachments, { id: action.payload.id }).concat([action.payload]) });
        case 'PROJECT_ATTACHMENT_REMOVED':
            if (!state)
                return null;
            return __assign({}, state, { attachments: array_1.not(state.attachments, { id: action.payload.id }) });
        case 'PROJECT_ATTACHMENT_VERSION_COMMENT_CREATED':
            if (!state)
                return null;
            return general_1.tap(array_1.firstOrFail(array_1.where(state.attachments, { id: action.payload.attachment.id })), function (attachment) { return (__assign({}, state, { attachments: array_1.not(state.attachments, { id: action.payload.attachment.id }).concat([
                    __assign({}, attachment, { versions: array_1.not(attachment.versions, { id: action.payload.version.id }).concat([
                            general_1.tap(array_1.firstOrFail(array_1.where(attachment.versions, {
                                id: action.payload.version.id,
                            })), function (v) { return (__assign({}, v, { comments: v.comments.concat([action.payload.comment]) })); }),
                        ]) }),
                ]) })); });
        case 'PROJECT_ATTACHMENT_VERSION_COMMENT_UPDATED':
            if (!state)
                return null;
            return general_1.tap(array_1.firstOrFail(array_1.where(state.attachments, { id: action.payload.attachment.id })), function (attachment) { return (__assign({}, state, { attachments: array_1.not(state.attachments, { id: action.payload.attachment.id }).concat([
                    __assign({}, attachment, { versions: array_1.not(attachment.versions, { id: action.payload.version.id }).concat([
                            general_1.tap(array_1.firstOrFail(array_1.where(attachment.versions, {
                                id: action.payload.version.id,
                            })), function (v) { return (__assign({}, v, { comments: array_1.not(v.comments, { id: action.payload.comment.id }).concat([
                                    action.payload.comment,
                                ]) })); }),
                        ]) }),
                ]) })); });
        case 'PROJECT_ATTACHMENT_VERSION_COMMENT_REMOVED':
            if (!state)
                return null;
            return general_1.tap(array_1.firstOrFail(array_1.where(state.attachments, { id: action.payload.attachment.id })), function (attachment) { return (__assign({}, state, { attachments: array_1.not(state.attachments, { id: action.payload.attachment.id }).concat([
                    __assign({}, attachment, { versions: array_1.not(attachment.versions, { id: action.payload.version.id }).concat([
                            general_1.tap(array_1.firstOrFail(array_1.where(attachment.versions, {
                                id: action.payload.version.id,
                            })), function (v) { return (__assign({}, v, { comments: array_1.not(v.comments, {
                                    id: action.payload.comment.id,
                                }) })); }),
                        ]) }),
                ]) })); });
        case 'PROJECT_LOGS_REFRESHED':
            if (!state)
                return null;
            return __assign({}, state, { logs: action.payload });
        case 'PROJECT_CLEAR':
            return null;
        default:
            return state;
    }
}
exports.project = project;
