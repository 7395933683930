"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var SsoFirstTimeComponent = /** @class */ (function () {
    function SsoFirstTimeComponent(router, store) {
        this.router = router;
        this.store = store;
        this.signedIn$ = this.store.pipe(rx_1.choose(function (x) { return x.signedIn; }));
        //
    }
    SsoFirstTimeComponent.prototype.reload = function () {
        this.router
            .navigate(['', { outlets: { secondary: null } }])
            .then(function () {
        });
    };
    return SsoFirstTimeComponent;
}());
exports.SsoFirstTimeComponent = SsoFirstTimeComponent;
